<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="巡检项目名称" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input
          v-decorator="[
            'name',
            { rules: [{ type: 'string', required: true, message: '项目名称不能为空' }], trigger: 'change' },
          ]"
          placeholder="请输入"
        ></a-input>
      </a-form-item>
      <a-form-item label="排序号" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-input-number
          style="width: 100%;"
          :step="0.1"
          v-decorator="[
            'sorter',
            {
              rules: [{ type: 'number', required: true, message: '排序号必须为数字' }],
              trigger: 'change',
              initialValue: 0,
            },
          ]"
          placeholder="请输入"
        ></a-input-number>
      </a-form-item>
      <a-form-item label="项目类型" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-select
          allowClear
          placeholder="请选择"
          @change="handleQuestionTypeChange"
          v-decorator="['type', { rules: [{ required: true, message: '请选择项目类型' }], trigger: 'change' }]"
        >
          <a-select-option v-for="item in questionTypeSelect" :key="item.value" :value="item.value" :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <div v-if="formItem.type === 'STATE'">
        <a-form-item
          v-for="(k, index) in form.getFieldValue('keys')"
          :key="k"
          :label-col="questionLabelCol"
          :wrapper-col="questionWrapperCol"
          :label="'第' + (index + 1) + '个可选项'"
          :required="false"
        >
          <a-input
            v-decorator="[
              `probablyOptionList[${k}]`,
              {
                validateTrigger: ['change', 'blur'],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: '请输入可选项',
                  },
                ],
              },
            ]"
            placeholder="请输入"
            @change="handleProbablyOptionChange"
            style="width: 93%; margin-right: 4px;"
          />
          <a-icon
            class="dynamic-delete-button"
            type="minus-circle-o"
            :disabled="form.getFieldValue('keys').length === 1"
            @click="() => removeProbablyOption(k)"
          />
        </a-form-item>
        <a-row style="margin-bottom: 24px;">
          <a-col :offset="5" :span="19">
            <a-button type="dashed" style="width: 88%;" @click="addProbablyOption">
              <a-icon type="plus" />
              添加可选项
            </a-button>
          </a-col>
        </a-row>
        <a-form-item label="默认值" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-select
            allowClear
            placeholder="请选择"
            @dropdownVisibleChange="handleQuestionDefaultValueSelectDropDown"
            v-decorator="['defaultValue', { rules: [{ required: true, message: '请选择默认值' }] }]"
          >
            <a-select-option v-for="item in defaultValueList" :key="item.value" :value="item.value" :label="item.label">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div v-if="formItem.type === 'NUMBER'">
        <a-form-item label="最大值" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input-number
            style="width: 100%;"
            :step="0.1"
            v-decorator="[
              'maxValue',
              {
                rules: [{ type: 'number', required: true, message: '最大值必须为数字' }],
                initialValue: 0,
              },
            ]"
            placeholder="请输入"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="最小值" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input-number
            style="width: 100%;"
            :step="0.1"
            v-decorator="[
              'minValue',
              {
                rules: [{ type: 'number', required: true, message: '最小值必须为数字' }],
                initialValue: 0,
              },
            ]"
            placeholder="请输入"
          ></a-input-number>
        </a-form-item>
        <a-form-item label="默认值" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input-number
            style="width: 100%;"
            :step="0.1"
            v-decorator="[
              'numberDefaultValue',
              { rules: [{ type: 'number', required: true, message: '默认值必须为数字' }], initialValue: 0 },
            ]"
            placeholder="请输入"
          ></a-input-number>
        </a-form-item>
      </div>
      <div v-if="formItem.type === 'TEXT'">
        <a-form-item label="默认值" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
          <a-input
            v-decorator="[
              'textDefaultValue',
              { rules: [{ type: 'string', required: true, message: '默认值不能为空' }] },
            ]"
            placeholder="请输入"
          ></a-input>
        </a-form-item>
      </div>
      <a-form-item label="操作描述" :label-col="questionLabelCol" :wrapper-col="questionWrapperCol">
        <a-textarea v-decorator="['actionDescribe']" placeholder="请输入" :rows="4" />
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { questionTypeSelect } from './common/common'
import questionFormVO from './common/questionFormVO'
import SERVICE_URLS from '@/api/service.url'
import entityCURDCallback from '../../common/mixins/entityCURDCallback'
let id = 0
export default {
  name: 'QuestionForm',
  mixins: [entityCURDCallback],
  props: {
    nodeId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      ifSub: false,
      questionLabelCol: { span: 5 },
      questionWrapperCol: { span: 17 },
      questionTypeSelect: questionTypeSelect(),
      initFormItem: questionFormVO(),
      entityBaseUrl: SERVICE_URLS.xj.xjItem,
      defaultValueList: [],
      showFormItem: false,
    }
  },
  created() {
    // ↓用于和表单进行双向绑定，单文件template可以使用指令v-decorator进行绑定，详见下方描述
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
  },
  methods: {
    handleQuestionTypeChange(value) {
      this.formItem.type = value
      this.form.resetFields(['defaultValue', 'numberDefaultValue', 'textDefaultValue'])
    },
    removeProbablyOption(k) {
      const { form } = this
      const keys = form.getFieldValue('keys')
      if (keys.length === 1) {
        return
      }
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k),
      })
      this.form.resetFields(['defaultValue'])
    },
    addProbablyOption() {
      const { form } = this
      const keys = form.getFieldValue('keys')
      const nextKeys = keys.concat(++id)
      form.setFieldsValue({
        keys: nextKeys,
      })
    },
    setVOFields(values) {
      // if (values.type === 'NUMBER') {
      //   if (values.maxValue < values.minValue) {
      //     this.$message.warning('最小值不能大于最大值！')
      //     this.$emit('editError', 'formError')
      //     return
      //   } else if (values.defaultValue < values.minValue) {
      //     this.$message.warning('最小值不能大于默认值！')
      //     this.$emit('editError', 'formError')
      //     return
      //   } else if (values.maxValue < values.defaultValue) {
      //     this.$message.warning('默认值不能大于最大值！')
      //     this.$emit('editError', 'formError')
      //     return
      //   }
      // }
      this.formItem.node.id = this.nodeId
      this.formItem.name = values.name
      this.formItem.sorter = values.sorter
      this.formItem.type = values.type
      this.formItem.probablyOption = []
      switch (values.type) {
        case 'STATE':
          values.probablyOptionList.forEach((item) => {
            this.formItem.probablyOption.push(item)
          })
          this.formItem.defaultValue = values.defaultValue
          this.formItem.maxValue = ''
          this.formItem.minValue = ''
          break
        case 'NUMBER':
          this.formItem.defaultValue = values.numberDefaultValue
          this.formItem.probablyOption = []
          this.formItem.maxValue = values.maxValue
          this.formItem.minValue = values.minValue
          break
        case 'TEXT':
          this.formItem.defaultValue = values.textDefaultValue
          this.formItem.probablyOption = []
          this.formItem.maxValue = ''
          this.formItem.minValue = ''
          break
      }
      this.formItem.actionDescribe = values.actionDescribe
    },
    setFields(values) {
      this.formItem = questionFormVO()
      this.form.resetFields()
      id = 0
      Object.assign(this.formItem, values)
      switch (values.type) {
        case 'STATE':
          // 设置项目类型
          this.form.setFieldsValue({
            type: values.type,
          })
          // 判断有多少个可选项
          let probablyOptionList = JSON.parse(values.probablyOption)
          probablyOptionList.forEach((item, index) => {
            this.defaultValueList.push({
              value: item,
              label: item,
            })
            this.addProbablyOption()
            this.$nextTick(() => {
              let inputObject = {}
              inputObject[`probablyOptionList[${index + 1}]`] = item
              this.form.setFieldsValue(inputObject)
            })
          })
          this.$nextTick(() => {
            this.form.setFieldsValue({
              name: this.formItem.name,
              sorter: this.formItem.sorter,
              actionDescribe: this.formItem.actionDescribe,
            })
            this.$nextTick(() => {
              this.form.setFieldsValue({
                defaultValue: this.formItem.defaultValue,
              })
            })
          })
          break
        case 'NUMBER':
          // 设置项目类型
          this.form.setFieldsValue({
            type: values.type,
          })
          this.$nextTick(() => {
            this.form.setFieldsValue({
              name: this.formItem.name,
              sorter: this.formItem.sorter,
              maxValue: this.formItem.maxValue,
              minValue: this.formItem.minValue,
              actionDescribe: this.formItem.actionDescribe,
            })
            this.$nextTick(() => {
              this.form.setFieldsValue({
                numberDefaultValue: Number(this.formItem.defaultValue),
              })
            })
          })
          break
        case 'TEXT':
          // 设置项目类型
          this.form.setFieldsValue({
            type: values.type,
          })
          this.$nextTick(() => {
            this.form.setFieldsValue({
              name: this.formItem.name,
              sorter: this.formItem.sorter,
              actionDescribe: this.formItem.actionDescribe,
            })
            this.$nextTick(() => {
              this.form.setFieldsValue({
                textDefaultValue: this.formItem.defaultValue,
              })
            })
          })
          break
      }
    },
    handleQuestionDefaultValueSelectDropDown() {
      this.defaultValueList = []
      if (this.form.fieldsStore.fields.keys) {
        this.form.fieldsStore.fields.keys.value.forEach((item) => {
          if (this.form.getFieldValue(`probablyOptionList[${item}]`)) {
            this.defaultValueList.push({
              value: this.form.getFieldValue(`probablyOptionList[${item}]`),
              label: this.form.getFieldValue(`probablyOptionList[${item}]`),
            })
          }
        })
      }
    },
    handleProbablyOptionChange() {
      this.form.resetFields(['defaultValue'])
    },
    addCopySuccessCallback() {
      this.form.validateFields((err, values) => {
        this.formItem.type = values.type
      })
    },
    addSuccessCallback(data) {
      // this.form.validateFields((err, values) => {
      //   this.formItem.type = values.type
      //    if (values.type === 'STATE') {
      //      // let probablyOptionList = JSON.parse(values.probablyOption)
      //      values.probablyOptionList.forEach((item, index) => {
      //        this.defaultValueList.push({
      //          value: item,
      //          label: item
      //        })
      //      })
      //    }
      // })
    },
  },
}
</script>

<style scoped>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
