<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="源巡检点" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          disabled
          style="width: 100%;"
          v-decorator="['yuanNodeName']"
        ></a-input>
      </a-form-item>
      <a-form-item label="巡检点类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select style="width: 100%;" placeholder="请选择" v-model="selectType">
          <a-select-option
            v-for="item in vindicateTypeSelectList"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="巡检点名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input style="width: 100%;" v-if="selectType === 'other'" v-decorator="['name',{ rules: [{ required: true, message: '请输入或选择巡检点名称' }] }]" placeholder="请填写名称">
        </a-input>
        <div v-if="selectType === 'monitoring_point'">
          <template v-for="(item, index) in selectDataList">
            <a-tag closable @close="tagClose(index)">{{ item.name }}</a-tag>
          </template>
          <div>
            <a-tag :closable="false" @click="clickMonitoringPoint" color="#108ee9">
              <a-icon type="plus"/>
              选择监测点
            </a-tag>
          </div>
        </div>
      </a-form-item>
      <div v-if="selectType === 'other'">
        <a-form-item label="所属检修部门" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-tree-select style="width: 100%;" placeholder="请选择所属检修部门" :treeData="deptList" v-decorator="['deptId',{ rules: [{ required: true, message: '请选择检修部门' }] }]"></a-tree-select>
        </a-form-item>
        <a-form-item label="所在位置" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-tree-select style="width: 170px;" :treeData="areaList" v-decorator="['area', { rules: [{ required: true, message: '请选择所在位置' }] }]" placeholder="请选择省市"></a-tree-select>
          <a-input style="width: 162px; margin-left: 2px;" v-decorator="['location']" placeholder="请输入地址"></a-input>
        </a-form-item>
        <a-form-item label="类型标记" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-input style="width: 162px; margin-left: 2px;" v-decorator="['tag']" placeholder="请输入类型标记"></a-input>
        </a-form-item>
      </div>
    </a-form>
    <a-modal
      :footer="null"
      :destroyOnClose="true"
      title="监测点"
      :visible="visible"
      @cancel="handCancel"
    >
      <monitoringPointTree @selectedTreeNode="selectedTreeNode" @selectTreeNodeDetail="selectTreeNodeDetail"></monitoringPointTree>
    </a-modal>
  </a-spin>
</template>

<script>
  import { copyFormVO } from './common/copyFormVo'
  import { vindicateTypeSelect } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import monitoringPointTree from '../../../components/monitoringPointTree/List'

  export default {
    name: 'Form',
    components: { monitoringPointTree },
    mixins: [],
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        entityBaseUrl: SERVICE_URLS.xj.xjNode,
        deviceList: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        formItem: copyFormVO(),
        spinShow: false,
        vindicateTypeSelectList: vindicateTypeSelect(),
        deptList: [],
        areaList: [],
        showFormItem: false,
        selectType: 'monitoring_point',
        nodeId: '',
        visible: false,
        selectDataList: []
      }
    },
    created () {
    },
    methods: {
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
            // this.$nextTick(() => {
            //   this.form.setFieldsValue({
            //     deptId: this.$store.getters.currentUser.organizationJobs[0].organizationId.toString(),
            //   })
            // })
          }
        })
      },
      loadAreaList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.areaTree,
          noTips: true,
          success: (data) => {
            this.areaList = data.body
          }
        })
      },
      loadData (record) {
        this.nodeId = record.id
        this.loadDept()
        this.loadAreaList()
        this.$nextTick(() => {
          this.form.setFieldsValue({
            yuanNodeName: record.name
          })
        })
      },
      handleAddSubmit () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.spinShow = true
            this.$http(this, {
              url: SERVICE_URLS.xj.xjNode.copy,
              params: {
                nodeId: this.nodeId
              },
              data: this.formItem,
              noTips: true,
              success: (data) => {
                //添加成功，清空表单数据，并触发回调
                this.$emit('copyAddSuccess', data.body)
                //清空表单数据
                this.formItem = copyFormVO()
                this.form.resetFields()
                this.selectDataList = []
              },
              error: () => {
                this.$emit('addError', 'httpError')
              }
            })
          } else {
            this.$emit('addError', 'formError')
          }
        })
      },
      setVOFields (values) {
        if (this.selectType === 'monitoring_point') {
          this.formItem.list = this.selectDataList
        }
        if (this.selectType === 'other') {
          this.formItem.list.push({
            area: {
              id: values.area
            },
            department: {
              id: values.deptId
            },
            id: null,
            location: values.location,
            name: values.name,
            tag: values.tag
          })
        }
        this.formItem.type = this.selectType
      },
      tagClose (index, type) {
        this.selectDataList.splice(index, 1)
      },
      clickMonitoringPoint () {
        this.visible = true
      },
      handCancel () {
        this.visible = false
      },
      selectedTreeNode (key, node) {
        if (node.dataRef.type === 'OTHER') {
          this.$message.warning('选中的节点为其他类型，请选择设备/设施')
        }
        // this.selectDataList.push(node.dataRef)
      },
      selectTreeNodeDetail (detail) {
        this.$message.success('选择成功')
        const list = {
          area: {
            id: detail.areaId
          },
          department: {
            id: detail.orgId
          },
          id: detail.id,
          location: detail.location,
          name: detail.name,
          tag: detail.tag
        }
        this.selectDataList.push(list)
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
