<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">巡检项目维护</h1>
    </div>
    <div slot="extra">
      <a-button style="margin-left: 16px;" icon="plus" @click="handleAdd" type="primary">新增</a-button>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="巡检点名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入名称"/>
            </a-form-item>
            <a-form-item label="标记">
              <a-input v-model="searchParams.tag" @change="search" placeholder="请输入标记"/>
            </a-form-item>
            <a-form-item label="类型">
              <a-select allowClear v-model="searchParams.type" @change="search" placeholder="请选择类型">
                <a-select-option
                  v-for="item in vindicateTypeSelect"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属维护部门">
              <a-tree-select
                style="width: 210px;"
                allowClear
                @change="search"
                :treeData="deptList"
                placeholder="请选择部门"
                v-model="searchParams.departmentId"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="所在位置">
              <a-input v-model="searchParams.location" placeholder="请输入位置" @change="search"/>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button class="m-l-6" type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type === 'device_info'">在线设备</span>
          <span v-if="record.type === 'facility_info'">设施</span>
          <span v-if="record.type === 'monitoring_point'">监测点</span>
          <span v-if="record.type === 'other'">其他</span>
        </template>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:;" @click="handleItem(record)">添加项目</a>
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="handleCopy(record)">复制</a>
          <a-divider type="vertical"/>
          <a @click="handleEdit(record.id)">编辑</a>
          <a-divider type="vertical"/>
          <a href="javascript:;" @click="handleDelete(record.id)">删除</a>
        </span>
      </a-table>
    </a-card>
    <a-modal :width="550" :visible="addModal" onOk="保存" title="新增巡检点" @ok="handleAddOk" @cancel="closeAddModal">
      <Form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"></Form>
    </a-modal>
    <a-modal :width="550" title="修改巡检点" :visible="editModal" @ok="handleEditOk" @cancel="closeEditModal">
      <Form ref="editRef" @editSuccess="editSuccess" @editError="editError"></Form>
    </a-modal>
    <a-modal
      :width="1300"
      :title="modalTitle + '_巡检项目'"
      :visible="itemModal"
      :footer="null"
      @cancel="closeItemModal"
    >
      <question-list ref="questionListRef"></question-list>
    </a-modal>
    <a-modal :title="modalTitle + '_复制'" :visible="copyModal" @ok="handleCopyOk" @cancel="closeCopyModal">
      <CopyForm ref="copyFormRef" @copyAddSuccess="copyAddSuccess"></CopyForm>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { vindicateColumns, vindicateTypeSelect } from './common/common'
  import PageLayout from '@/components/page/PageLayout'
  import entityCRUD from '../../common/mixins/entityCRUD'
  import Form from './Form'
  import QuestionList from './QuestionList'
  import CopyForm from './CopyForm'

  export default {
    name: 'vindicateList',
    components: { PageLayout, Form, QuestionList, CopyForm },
    mixins: [entityCRUD],
    data () {
      return {
        vindicateTypeSelect: vindicateTypeSelect(),
        entityBaseUrl: SERVICE_URLS.xj.xjNode,
        initColumns: vindicateColumns(),
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          departmentId: undefined,
          location: undefined,
          name: '',
          tag: '',
          type: undefined,
        },
        deptList: [],
        editLoading: false,
        itemModal: false,
        copyModal: false,
        modalTitle: '',
      }
    },
    computed: {},
    created () {
      this.loadDept()
    },
    methods: {
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          },
        })
      },
      handleItem (record) {
        this.modalTitle = record.name
        this.itemModal = true
        this.$nextTick(() => {
          this.$refs.questionListRef.getList(record.id)
        })
      },
      closeItemModal () {
        this.itemModal = false
      },
      closeCopyModal () {
        this.copyModal = false
      },
      handleCopy (record) {
        this.modalTitle = record.name
        this.copyModal = true
        this.$nextTick(() => {
          this.$refs.copyFormRef.loadData(record)
        })
      },
      handleCopyOk () {
        this.$refs.copyFormRef.handleAddSubmit()
      },
      copyAddSuccess () {
        this.copyModal = false
        this.$refs.copyFormRef.spinShow = false
        this.search()
      },
    },
  }
</script>

<style scoped></style>
