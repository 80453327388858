export const basicInfoFormVO = function () {
  return {
    id: '',
    area: {
      id: ''
    },
    department: {
      id: ''
    },
    instanceId: '',
    location: '',
    name: '',
    tag: '',
    type: 'monitoring_point'
  }
}